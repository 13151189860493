<template>
  <div class="step-content family-category">
    <div class="memorial-profile-add-edit-form-category-header">
      <h2 class="memorial-profile-add-edit-form-category-name">Rodzina</h2>

      <button class="section-toggle" @click="toggleShowSection">
        <span>{{ isSectionOpen ? 'Zwiń sekcję' : 'Rozwiń sekcję' }}</span>

        <v-icon>{{ isSectionOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </button>
    </div>

    <div v-if="isSectionOpen">
      <div class="horizontal-separator"></div>

      <p class="memorial-profile-add-edit-form-category-description">Kategoria będzie zawierać informacje o
        rodzinie.</p>

      <div class="horizontal-separator"></div>

      <div v-for="(sectionItem, index) in category?.items" :key="sectionItem.id">
        <div class="memorial-profile-add-edit-form-category-item-header-wrapper">
          <p class="memorial-profile-add-edit-form-category-item-header">
            {{ index === 0 ? 'Członek rodziny' : 'Kolejny członek rodziny' }}
          </p>

          <v-btn
              class="memorial-profile-add-edit-form-category-delete-button"
              prepend-icon="mdi-delete-outline"
              @click="removeCategoryItem(sectionItem.id)"
          >Usuń
          </v-btn>
        </div>

        <v-select
            v-model="sectionItem.relation"
            :items="relationItems"
            :menu-props="{ closeOnContentClick: true }"
            label="Powiazanie rodzinne"
        >
          <template #selection="{ item }">
            <v-list-item-title>{{ relationDict[item.value] }}</v-list-item-title>
          </template>

          <template #item="{ item }">
            <v-list-item @click="selectItem(item, sectionItem)">
              <v-list-item-title>{{ relationDict[item.value] }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-select>

        <v-text-field v-model="sectionItem.name" label="Imię"></v-text-field>
      </div>
      <v-btn
          append-icon="mdi-plus"
          class="memorial-profile-add-edit-form-category-add-item-button"
          @click="addCategoryItem"
      >Dodaj kolejnego członka rodziny
      </v-btn>

      <div class="horizontal-separator"></div>

      <div class="memorial-profile-add-edit-form-category-footer-buttons">
        <v-btn
            class="memorial-profile-add-edit-form-category-delete-button"
            prepend-icon="mdi-delete-outline"
            @click="removeSection"
        >Usuń sekcję
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>

import {shallowRef} from "vue";
import {useMemorialProfileStore} from "@/stores/memorial-profile";

export default {
  name: 'FamilyCategory',
  props: {
    profileData: {
      type: Object,
      default: () => ({
        sections: [],
      }),
    },
    category: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        key: 'family',
        items: [{
          name: {
            type: String,
            required: true,
            default: null,
          },
          relation: {
            type: String,
            required: true,
            default: null,
          },
        }],
      }),
    },
  },
  emits: ['section-updated'],
  setup() {
    /**
     * The possible future options:
     *    wife: 'Żona',
     *    husband: 'Mąż',
     *    partnership_female: 'Partnerka',
     *    partnership_male: 'Partner',
     *    son: 'Syn',
     *    daughter: 'Córka',
     *    mother: 'Matka',
     *    father: 'Ojciec',
     *    sister: 'Siostra',
     *    brother: 'Brat',
     *    ex_wife: 'Była żona',
     *    ex_husband: 'Były mąż',
     */
    const relationDict = {
      marriage: 'Małżeństwo',
      child: 'Dziecko',
      parent: 'Rodzic',
      sibling: 'Rodzeństwo',
    };
    const relationItems = shallowRef(Object.keys(relationDict));
    const selectedRelation = shallowRef(null);
    const store = useMemorialProfileStore();

    return {
      relationDict,
      relationItems,
      selectedRelation,
      store,
    }
  },
  data() {
    return {
      categoryName: 'family',
      isSectionOpen: true,
    }
  },
  methods: {
    selectItem(item, sectionItem) {
      sectionItem.relation = item.value;
    },
    toggleShowSection() {
      this.isSectionOpen = !this.isSectionOpen;
    },
    addCategoryItem() {
      if (!this.category) {
        return;
      }

      this.store.addSectionItemState(this.categoryName);
      this.$emit('section-updated');
    },
    removeSection() {
      this.store.removeSectionState(this.category.id);
      this.$emit('section-updated');
    },
    removeCategoryItem(itemId) {
      this.store.removeSectionItemState(this.category.id, itemId);
      this.$emit('section-updated');
    },
  }
}
</script>

<style scoped>
.family-category {
  /* Add your styles here */
}
</style>
