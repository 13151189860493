<template>
  <div class="step-content gallery-category">
    <div class="memorial-profile-add-edit-form-category-header">
      <h2 class="memorial-profile-add-edit-form-category-name">Galeria</h2>

      <button class="section-toggle" @click="toggleShowSection">
        <span>{{ isSectionOpen ? 'Zwiń sekcję' : 'Rozwiń sekcję' }}</span>

        <v-icon>{{ isSectionOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </button>
    </div>

    <div v-if="isSectionOpen">
      <div class="horizontal-separator"></div>

      <p class="memorial-profile-add-edit-form-category-description">Tutaj możesz stworzyć galerię zdjęć.</p>

      <div class="horizontal-separator"></div>

      <DragAndDropField
          :is-multiple="true"
          :is-preview="false"
          @files-changed="handleFilesChange($event)"
      />

      <div class="gallery-files">
        <p class="memorial-profile-add-edit-form-category-item-header gallery-image-list-header">
          Lista przesłanych zdjęć
        </p>

        <div v-for="imageData in galleryImages" :key="imageData.relative_path">
          <div v-if="imageData" class="gallery-image-wrapper">
            <div class="gallery-image-container">
              <img
                  :src="apiUrl + imageData.relative_path"
                  alt="Gallery Image"
                  class="gallery-image"
                  height="64"
                  width="64"
              />

              <div class="image-description">
                <div class="file-size">{{ formatSizeUnits(imageData.size) }}</div>
              </div>
            </div>

            <v-btn
                class="memorial-profile-add-edit-form-category-delete-button"
                prepend-icon="mdi-delete-outline"
                @click="handleDeleteImage(imageData.relative_path)"
            >Usuń
            </v-btn>
          </div>
        </div>
      </div>

      <div class="horizontal-separator"></div>

      <div class="memorial-profile-add-edit-form-category-footer-buttons">
        <v-btn
            class="memorial-profile-add-edit-form-category-delete-button"
            prepend-icon="mdi-delete-outline"
            @click="removeSection"
        >Usuń sekcję
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DragAndDropField from "@/components/DragAndDropField.vue";
import { useMemorialProfileStore } from "@/stores/memorial-profile";

export default {
  name: 'GalleryCategory',
  components: {
    DragAndDropField
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    sections: {
      type: Array,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useMemorialProfileStore();
    return { store };
  },
  data() {
    return {
      categoryName: 'gallery',
      galleryImages: [],
      isSectionOpen: true,
      apiUrl: process.env.VUE_APP_API_URL,
      photoUrl: '',
    }
  },
  mounted() {
    this.getProfileGalleryImages();
  },
  methods: {
    handleFilesChange({ files }) {
      if (!files?.length) {
        return;
      }

      const file = files[0];

      this.store.uploadImage({ file, categoryName: this.categoryName })
        .then(() => {
          this.getProfileGalleryImages();
        })
        .catch(error => {
          console.error('Error uploading image:', error);
        });
    },
    handleDeleteImage(photoUrl) {
      this.store.deleteImage({ photoUrl, categoryName: this.categoryName })
        .then(() => {
          this.getProfileGalleryImages();
        })
        .catch(error => {
          console.error('Error deleting image:', error);
        });
    },
    toggleShowSection() {
      this.isSectionOpen = !this.isSectionOpen;
    },
    removeSection() {
      this.store.removeSectionState(this.category.id);
    },
    setGalleryImages(images) {
      if (images?.length) {
        this.galleryImages = images.sort((a, b) => {
          const orderNumberA = parseInt(a.name.split('-')[1]);
          const orderNumberB = parseInt(b.name.split('-')[1]);
          return orderNumberA - orderNumberB;
        });
      } else {
        this.galleryImages = [];
      }
    },
    async getProfileGalleryImages() {
      try {
        const slotNumber = sessionStorage.getItem('slotNumber');

        if (!slotNumber) {
          return;
        }

        const response = await axios.get(`/media/images/${slotNumber}/gallery`);

        this.setGalleryImages(response.data.gallery_images);

        const imagePaths = response?.data?.gallery_images
            ? response.data.gallery_images.map(({relative_path}) => relative_path)
            : [];

        this.store.updateGalleryImagesState(imagePaths);
      } catch (error) {
        console.error('Błąd pobierania galerii:', error);
      }
    },
    formatSizeUnits(size) {
      if (size >= 1073741824) {
        return (size / 1073741824).toFixed(2) + ' GB';
      } else if (size >= 1048576) {
        return (size / 1048576).toFixed(2) + ' MB';
      } else if (size >= 1024) {
        return (size / 1024).toFixed(2) + ' kB';
      } else if (size > 1) {
        return size + ' bytes';
      } else if (size === 1) {
        return size + ' byte';
      } else {
        return '0 bytes';
      }
    },
  },
}
</script>

<style scoped>
.gallery-image-list-header {
  margin-bottom: 16px;
}

.gallery-image-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 20px;
  border: 1px solid #A3A3A3;
  border-radius: 4px;
}

.gallery-image-container {
  display: flex;
  gap: 8px;
}

.image-description {
  height: 100%;
  display: flex;
  flex-direction: column;

  .file-size {
    font-weight: 400;
    font-size: 0.75rem;
    color: #737373;
  }
}
</style>
